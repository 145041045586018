<div *ngIf="showCart" class="panier-container">
    <h2>Cart</h2>
    <ul>
      <li *ngFor="let item of items$ | async">
        <div>Orbite : <span class="data-value">{{ item.orbite }}</span></div>
        <div>Acquisition Date : <span class="data-value">{{ item.acquisitiondate }}</span></div>
      
        <button class="deleteButton" (click)="removeItem(item)">
          <mat-icon>delete</mat-icon>
        </button>
      </li>
    </ul>
    <div class="total">
      Total: {{ getTotal()  }}
    </div>
    <div class="button-container">
      <button class="orderButton" [disabled]="!(items$ | async)?.length" (click)="orderItems()">Order</button>
    </div>
</div>