<!--<div class="python-editor">
    <div class="toolbar">
        <button class="toolbar-button execute-button" (click)="onExecuteClick()"></button>
        <button class="toolbar-button save-button"></button>
    </div>
    <div class="monaco-editor">
        <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code" ></ngx-monaco-editor>
    </div>
    <div class="output-pane">
        <p >{{ output }}</p>
    </div>
    <div class="error-pane">
        <p >{{ error_occured }}</p>
    </div>
</div>-->




<div *ngIf="showCodeEditor" class="python-editor">
    <div class="toolbar">
        <button class="toolbar-button execute-button" (click)="onExecuteClick()"></button>
        <button class="toolbar-button save-button"></button>
    </div>

    <as-split direction="horizontal">
        <as-split-area size="10">
            <div class="files-pane">
                
            </div>
        </as-split-area>
        <as-split-area size="90">
            <div class="monaco-editor">
                <ngx-monaco-editor [options]="editorOptions" [(ngModel)]="code" ></ngx-monaco-editor>
            </div>
            <div class="output-pane">
                <p >{{ output }}</p>
            </div>
            <!--<div class="error-pane">
                <p >{{ error_occured }}</p>
            </div>-->
        </as-split-area>
      </as-split>
      




</div>

