import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private centerSubject = new BehaviorSubject<{latitude: number, longitude: number}>({latitude: 0, longitude: 0});

  setCenter(latitude: number, longitude: number) {
    this.centerSubject.next({latitude, longitude});
  }

  getCenter(): Observable<{latitude: number, longitude: number}> {
    console.log('getCenter');
    return this.centerSubject.asObservable();
  }

  constructor() { }
}
